<template>
  <div>
    <template v-if="!loading">
      <div class="main-slider main-slider_mod-1 has-edit" id="main-slider">
        <edit-button @click="edit_slider = true" />
        <carousel :autoplay="true" :items="1" :dots="false" :nav="false">
          <div class="item" v-for="(slide, i) in slider" :key="`slide-${i}`">
            <div class="item-inner">
              <img :src="slide.image" alt="img" />
            </div>
            <div class="item-wrap">
              <div class="container">
                <div class="slide-title main-slider_zoomIn">
                  {{ slide.title }}
                </div>
                <p class="slide-text main-slider_fadeInLeft">
                  {{ slide.tagline }}
                </p>
                <a
                  href="#how-we-can-help"
                  class="btn btn-primary main-slider_fadeInLeft"
                  >Learn More</a
                >
              </div>
            </div>
          </div>
        </carousel>
      </div>
      <section class="section-area section_advantages" id="overview">
        <div class="border-wave border-wave_white"></div>
        <div class="container has-edit">
          <edit-button @click="edit_intro = true" v-if="!edit_intro" />
          <form @submit.prevent="">
            <div class="row">
              <div data-wow-delay=".5s" class="col-md-4">
                <h2 class="ui-title-block">WELCOME TO</h2>
                <div class="ui-subtitle-block">
                  REALHUB LEGAL AND SURVEYING SERVICES
                </div>
                <div v-if="!edit_intro" v-html="intro.about"></div>
                <text-edit v-if="edit_intro" v-model="intro.about" />
              </div>
              <div class="col-md-8">
                <ul class="advantages-list">
                  <li
                    class="advantages-list__item"
                    v-for="(feature, i) in intro.features"
                    :key="`feature-${i}`"
                  >
                    <div class="pennant">
                      <i class="icon fa fa-life-ring"></i>
                    </div>
                    <div class="advantages-list__inner">
                      <h3 class="advantages-list__title" v-if="!edit_intro">
                        {{ feature.title }}
                      </h3>
                      <h3 class="advantages-list__title" v-if="edit_intro">
                        <input
                          type="text"
                          v-model="feature.title"
                          class="form-control"
                        />
                      </h3>
                      <p class="ui-text" v-if="!edit_intro">
                        {{ feature.title }}
                      </p>
                      <p class="ui-text" v-if="edit_intro">
                        <textarea
                          class="form-control"
                          rows="4"
                          v-model="feature.content"
                        ></textarea>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="form-group" v-if="edit_intro">
                  <button class="btn btn-primary">Save</button>
                  <a
                    href="#"
                    class="btn btn-warning float-right"
                    @click.prevent="edit_intro = false"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section
        class="section-area section_form-order"
        id="request-estimate"
        style="padding: 1rem 0"
      >
        <div class="container">
          <div class="row" v-if="!submiting_request">
            <div class="col-xs-12">
              <form
                id="contactForm"
                class="form-order"
                @submit.prevent="submit"
              >
                <h2 class="form-order__title">
                  <i class="icon flaticon-package36"></i>
                  <span class="border-color"></span>
                  REQUEST A FREE<strong>ESTIMATE</strong>
                </h2>
                <div class="form-order__inner">
                  <div id="success"></div>

                  <div class="input-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="user-name"
                      id="user-name"
                      placeholder="Full Name"
                      required
                      class="form-control"
                      v-model="request.name"
                    />
                  </div>
                  <div class="input-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="user-email"
                      id="user-email"
                      placeholder="Email Address"
                      required
                      class="form-control"
                      v-model="request.email"
                    />
                  </div>
                  <div class="input-group">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      name="user-phone"
                      id="user-phone"
                      placeholder="Phone Number"
                      class="form-control"
                      v-model="request.phone_number"
                    />
                  </div>
                  <div class="input-group">
                    <label>Service</label>
                    <select v-model="request.service" class="form-control">
                      <option value="legal">Legal Services</option>
                      <option value="surveying">Surveying Services</option>
                    </select>
                  </div>
                  <div class="input-group input-group_date"></div>
                  <div class="input-group input-group_date"></div>
                  <div class="input-group input-group_full_width">
                    <label>Details</label>
                    <textarea
                      placeholder="Details"
                      required
                      class="form-control"
                      rows="4"
                      v-model="request.comments"
                    ></textarea>
                  </div>
                  <button class="btn btn-secondary">GET QUICK ESTIMATE</button>
                </div>
              </form>
            </div>
          </div>
          <loading v-if="submiting_request" />
        </div>
      </section>

      <section
        class="section-area section_mod-1"
        :style="`background-image: url(${about.image})`"
      >
        <div class="container has-edit">
          <edit-button @click="edit_about = true" v-if="!edit_about" />
          <form @submit.prevent="updateAbout">
            <div class="row">
              <div class="col-md-8">
                <div class="inner">
                  <h2 class="ui-subtitle-block" v-if="!edit_about">
                    {{ about.title }}
                  </h2>
                  <h2 class="ui-subtitle-block" v-if="edit_about">
                    <input
                      type="text"
                      class="form-control"
                      v-model="about.title"
                    />
                  </h2>
                  <div class="ui-title-block" v-if="!edit_about">
                    {{ about.tagline }}
                  </div>
                  <div class="ui-title-block" v-if="edit_about">
                    <input
                      type="text"
                      class="form-control"
                      v-model="about.tagline"
                    />
                    <input
                      type="file"
                      class="form-control mt-2"
                      ref="about_image"
                    />
                  </div>
                  <div class="border-color"></div>
                  <div v-if="!edit_about" v-html="about.content"></div>
                  <div v-if="edit_about">
                    <text-edit v-model="about.content" />
                  </div>
                  <ul class="list-mark">
                    <li
                      class="list-mark__item"
                      v-for="(feature, i) in about.features"
                      :key="`feature-${i}`"
                    >
                      <a
                        href="javascript:void(0);"
                        class="list-mark__link"
                        v-if="!edit_about"
                        ><i class="icon"></i>{{ feature }}</a
                      >
                      <input
                        type="text"
                        v-if="edit_about"
                        v-model="about.features[i]"
                        class="form-control"
                      />
                    </li>
                  </ul>
                  <div class="form-group" v-if="edit_about">
                    <button class="btn btn-success">Save</button>
                    <a
                      href="#"
                      @click.prevent="edit_about = false"
                      class="btn btn-warning float-right"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <div
        class="section-area section-default"
        style="padding: 40px 0"
        id="how-we-can-help"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-6 has-edit">
              <edit-button @click="edit_how = true" v-if="!edit_how" />
              <form @submit.prevent="updateHow">
                <section class="section_mod-2">
                  <h2 class="ui-title-block">BEST CONSULTING SERVICES</h2>
                  <div class="ui-subtitle-block">HOW WE CAN HELP YOU</div>
                  <div class="border-color border-color_default"></div>
                  <div v-if="!edit_how" v-html="how.content"></div>
                  <div v-if="edit_how">
                    <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
                      v-model="how.content"
                      :init="{
                        height: 350,
                        menubar: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | image',
                      }"
                    />
                  </div>
                  <ul class="list-mark list-mark_small">
                    <li
                      class="list-mark__item"
                      v-for="(item, i) in how.features"
                      :key="`how-${i}`"
                    >
                      <a
                        href="javascript:void(0);"
                        class="list-mark__link"
                        v-if="!edit_how"
                        ><i class="icon"></i>{{ item }}</a
                      >
                      <input
                        type="text"
                        v-if="edit_how"
                        v-model="how.features[i]"
                        class="form-control"
                      />
                    </li>
                  </ul>
                  <div class="form-group" v-if="edit_how">
                    <button class="btn btn-success">Save</button>
                    <a
                      href="#"
                      class="btn btn-warning float-right"
                      @click.prevent="edit_how = false"
                      >Cancel</a
                    >
                  </div>
                </section>
              </form>
            </div>
            <div class="col-md-6">
              <section class="section_mod-2">
                <a
                  href="#"
                  class="btn btn-primary float-right btn-sm"
                  data-toggle="modal"
                  data-target="#add-review"
                  v-if="$auth.hasToken()"
                  >Leave A Review</a
                >
                <div
                  class="modal fade"
                  id="add-review"
                  tabindex="-1"
                  aria-labelledby="add-review-label"
                  aria-hidden="true"
                  v-if="$auth.hasToken()"
                >
                  <div class="modal-dialog">
                    <div class="modal-content" style="padding: 20px">
                      <div class="modal-header" style="margin-bottom: 10px">
                        <button
                          type="button"
                          class="btn btn-link float-right"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          X
                        </button>
                        <h2 class="modal-title" id="add-review-label">
                          Leave A Review
                        </h2>
                      </div>
                      <div class="py-3 px-4">
                        <form @submit.prevent="submitReview">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Your Name</label
                                ><input
                                  type="text"
                                  placeholder="Full Name"
                                  required="required"
                                  class="form-control"
                                  v-model="review.name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Your Email</label
                                ><input
                                  type="text"
                                  placeholder="Full Email"
                                  required="required"
                                  class="form-control"
                                  v-model="review.email"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Your Phone Number</label
                                ><input
                                  type="text"
                                  placeholder="Full Phone Number"
                                  required="required"
                                  class="form-control"
                                  v-model="review.phone_number"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Rate Your Experience</label>
                                <input-rating
                                  class="py-3"
                                  v-model="review.rating"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Your Comments</label
                                ><textarea
                                  rows="6"
                                  placeholder="Your Comments"
                                  required="required"
                                  class="form-control"
                                  v-model="review.content"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <button class="btn btn-primary">
                                  <i class="lni lni-save"></i> Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                          id="close-add-review-modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 class="ui-title-block">WHAT THEY SAID</h2>
                <div class="ui-subtitle-block">CLIENTS TESTIMONIALS</div>
                <div class="border-color border-color_default"></div>
                <div
                  id="slider-reviews"
                  class="slider-reviews slider-reviews_mod-2"
                  v-if="! reviews_loading"
                >
                  <div class="slide" v-if="reviews.length == 0">
                    <alert type="info">There are no reviews yet.</alert>
                  </div>
                  <carousel :items="1" :autoplay="true" v-if="reviews.length > 0">
                    <div
                      class="slide mt-2"
                      v-for="(r, i) in reviews"
                      :key="`slide-${i}`"
                    >
                      <div class="slider-reviews__quote">
                        <blockquote>
                          <p>{{ r.content }}</p>
                        </blockquote>
                        <div class="round-figure">
                          <img
                            :src="r.avatar"
                            :alt="r.name"
                            width="60"
                            height="60"
                          />
                        </div>
                        <span class="slider-reviews__autor"
                          >-- {{ r.name }}</span
                        >
                      </div>
                    </div>
                  </carousel>
                  <loading v-if="reviews_loading" />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading v-if="loading" />
    <modal :show="edit_slider" @close="edit_slider = false">
      <template slot="header">Edit Slider</template>
      <form @submit.prevent="updateSlider">
        <a href="#" class="text-success" @click.prevent="slider.push({})"
          >Add Slide</a
        >
        <div
          class="card pb-0"
          v-for="(slide, i) in slider"
          :key="`edit-slide-${i}`"
        >
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="slide.title"
            />
          </div>
          <div class="form-group">
            <label>Tagline</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="slide.tagline"
            />
          </div>
          <div class="form-group">
            <label>Background</label>
            <input
              type="file"
              class="form-control"
              required
              ref="background_slide"
            />
          </div>
          <div class="form-group text-right">
            <a href="#" class="text-danger" @click.prevent="slider.splice(i, 1)"
              >Remove</a
            >
          </div>
        </div>
        <div class="form-group" v-if="slider.length > 0">
          <button class="btn btn-primary">
            <i class="lni lni-save"></i>Save
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();

    this.fetchReviews();

    this.review = {
      name: this.user.name,
      email: this.user.email,
      phone_number: this.user.phone_number,
    };
  },

  data() {
    return {
      loading: true,
      slider: [],
      edit_slider: false,
      intro: {},
      edit_intro: false,
      about: {},
      edit_about: false,
      request: { service: "legal" },
      selected_region: null,
      regions: [],
      submiting_request: false,
      how: {},
      edit_how: false,
      review: {},
      reviews: [],
      reviews_loading: true,
      reviews_pagination: { page: 1, records: 1 },
    };
  },

  methods: {
    fetchReviews() {
      this.reviews_loading = true;

      this.$axios
        .get(`/api/v1/reviews/legal-and-surveying?page=${this.reviews_pagination.page}`)
        .then((response) => {
          this.reviews_loading = false;
          this.reviews = response.data.reviews;
        });
    },

    submitReview() {
      let data = this.review;
      data.type = "legal-and-surveying";

      this.$axios.post(`/api/v1/reviews/legal-and-surveying`, data).then(() => {
        this.fetchReviews();
        this.$swal("success", "Your review has been submitted.", "Success");
        document.getElementById("close-add-review-modal").click();
      });
    },

    fetch() {
      this.loading = true;

      this.$axios
        .get("/api/v1/content/legal-and-surveying")
        .then((response) => {
          this.slider = response.data.content.slider;
          this.intro = response.data.content.intro;
          this.about = response.data.content.about;
          this.how = response.data.content.how;
          this.regions = response.data.content.regions;
          this.loading = false;
        });
    },

    updateSlider() {
      let data = new FormData();
      data.append("type", "legal-and-surveying-slider");

      this.slider.forEach((slide, i) => {
        data.append(`content[${i}][title]`, slide.title);
        data.append(`content[${i}][tagline]`, slide.tagline);
        data.append(
          `content[${i}][image]`,
          this.$refs.background_slide[i].files[0]
        );
      });

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_slider = false;
        this.fetch();
      });
    },

    updateAbout() {
      let data = new FormData();
      data.append("type", "legal-and-surveying-about");
      data.append("content[title]", this.about.title);
      data.append("content[tagline]", this.about.tagline);
      data.append("content[content]", this.about.content);
      data.append("content[image]", this.$refs.about_image.files[0]);

      this.about.features.forEach((feature, i) => {
        data.append(`content[features][${i}]`, feature);
      });

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_about = false;
        this.fetch();
      });
    },

    updateHow() {
      let data = {
        type: "legal-and-surveying-how",
        content: this.how,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_how = false;
        this.fetch();
      });
    },

    submit() {
      let data = this.request;
      this.submiting_request = true;

      this.$axios
        .post("/api/v1/services/legal-surveying", data)
        .then(() => {
          this.$swal(
            "Success",
            "We have received your request and will contact you shortly.",
            "success"
          );
          this.submiting_request = false;
        })
        .catch(() => {
          this.$swal(
            "Error",
            "There was an error processing your request. Please try again later.",
            "error"
          );
          this.submiting_request = false;
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
