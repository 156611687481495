<template>
  <div class="layout-theme animated-css theme-2">
    <div id="wrapper">
      <div class="main">
        <header class="header">
          <div class="header__top">
            <top-bar />
          </div>
          <!-- end header__top .-->
          <div class="container">
            <div class="row">
              <div class="col-xs-12 header-nav">
                <div class="header__logo">
                  <a href="/movers" class="logo">
                    <img src="/logo.png" alt="Logo" height="60px" />
                    <span>Legal & Surveying</span>
                  </a>
                </div>
                <div class="navbar">
                  <div class="yamm">
                    <div class="navbar-header hidden-md hidden-lg hidden-sm">
                      <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-1"
                        class="navbar-toggle"
                      >
                        <span class="icon-bar"></span
                        ><span class="icon-bar"></span
                        ><span class="icon-bar"></span>
                      </button>
                      <a href="#" class="navbar-brand">Menu</a>
                    </div>
                    <nav
                      id="navbar-collapse-1"
                      class="navbar-collapse collapse"
                    >
                      <ul class="nav">
                        <li><a href="#main-slider">Home</a></li>
                        <li><a href="#overview">Overview</a></li>
                        <li><a href="#request-estimate">Request Estimate</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#testimonials">Testimonials</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <!-- end header .-->
        
        <router-view />

        <services-contacts />

        <footer class="footer">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h3 class="ui-title-inner">ABOUT REALHUB</h3>
                <div class="border-color"></div>
                <p>
                  Welcome to RealHub Kenya! We are a property listing platform that connects agents, agencies, and real estate companies with buyers, sellers, and renters looking for the perfect home. Our platform is designed to make the process of finding a new place to live or work as simple and stress-free as possible.
                </p>
                <form class="form-footer">
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="NEWSLETTER: Your Email"
                      class="form-control"
                    />
                    <i class="icon fa fa-envelope"></i>
                  </div>
                </form>
              </div>
              <div class="col-md-4">
                <h3 class="ui-title-inner">CATEGORIES</h3>
                <div class="border-color"></div>
                <ul class="list-mark">
                  <li class="list-mark__item" v-for="(category, i) in categories" :key="`category-${i}`">
                    <router-link :to="{ name: 'page', params: { slug: category.slug }}" class="list-mark__link"
                      ><span class="icon"></span>{{ category.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h3 class="ui-title-inner">CONTACT US</h3>
                <div class="border-color"></div>
                <div class="footer__title-block">Office Address</div>
                <p>{{ contact.location }}</p>
                <div class="footer__title-block">Phone Number</div>
                <p>{{ contact.phone_number }}</p>
                <div class="footer__title-block">Email</div>
                <p>
                  <a :href="`mailto:${contact.email}`" class="footer__mail"
                    >{{ contact.email }}</a
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="copyright">
                <span>© Copyrights 2023<strong> REALHUB.</strong></span
                ><span> All Rights Reserved.</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import '@/theme-2.js'
import TopBar from './components/TopBar.vue'

export default {
  components: {
    TopBar
  },

  mounted() {
    document.title = 'Professional Legal and Surveying Services in Kenya with Realhub: Transform Your Space Today'
  },

  computed: {
    contact() {
      return  this.$store.getters.getContact
    },

    categories() {
      return this.$store.getters.getMenuCategories;
    },
  }
}
</script>